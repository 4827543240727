import { alpha, styled } from '@mui/material/styles';

import { palette, Theme } from '../../../theme';
import {
    StyledButtonProperties,
    ToggleButtonLayoutVariant,
} from './ToggleButton.types';

const regularStyle = () => `
    color: ${palette.primary.main};
    background-color: ${palette.common.white};

    &:hover {
        color: ${palette.primary.main};
        background-color: ${palette.common.white};
    }
`;

const errorStyle = () => `
    border: 0.2rem solid ${alpha(palette.error.main, 0.2)};
    color: ${palette.error.main};
    background-color: ${palette.common.white};

    &:hover {
        color: ${palette.error.main};
        background-color: ${palette.common.white};
    }
`;

const checkedStyle = () => `
    color: ${palette.common.blue};
    background-color: ${palette.secondary.main};

    &:hover {
        color: ${palette.common.blue};
        background-color: ${palette.secondary.main};
    }
`;

export const StyledWrapper = styled('div')<{
    $center?: boolean;
    $layoutVariant: ToggleButtonLayoutVariant;
}>`
    display: flex;
    flex-direction: ${({ $layoutVariant }) =>
        $layoutVariant === 'horizontal' ? 'row' : 'column'};
    justify-content: ${({ $center }) => ($center ? 'center' : 'initial')};

    > span {
        flex-basis: 0;
        flex-grow: 1;

        margin-right: ${({ $layoutVariant, theme }) =>
            $layoutVariant === 'horizontal' ? theme.spacing(15) : '0'};

        &:last-child {
            margin-top: ${({ $layoutVariant }) =>
                $layoutVariant === 'horizontal' ? '0' : '2rem'};

            margin-right: 0;
        }
    }

    > :last-child {
        margin-top: ${({ $layoutVariant }) =>
            $layoutVariant === 'horizontal' ? '0' : '2rem'};
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;

        > :not(:last-child) {
            margin-right: 2rem;
        }
        > :last-child {
            margin-top: 0;
        }
    }
`;

export const StyledButtonGroup = styled('span')`
    display: flex;
    flex-direction: column;
`;

export const StyledInput = styled('input')`
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    appearance: none;

    &:focus-visible + label {
        outline: 0.2rem solid ${palette.primary.dark};
    }

    &:checked + label {
        border: 0.2rem solid ${alpha('#000', 0.2)};
        ${({ theme }) => checkedStyle()}
    }
`;

export const StyledButton = styled('label')<StyledButtonProperties>`
    width: auto;
    margin-bottom: 0.5rem;
    padding: 0.55rem 2rem;
    font-size: 1.9rem;
    font-family: thegymgroupsans-headline;
    line-height: 1.75;
    text-align: center;
    text-transform: lowercase;
    border: 0.2rem solid ${alpha(palette.primary.main, 0.2)};
    border-radius: 2.3rem;
    cursor: pointer;
    opacity: ${({ $disabled }) => ($disabled ? '0.5' : '1')};
    ${({ $error }) => ($error ? errorStyle() : regularStyle())}
`;

export const StyledErrorMessage = styled('div')`
    color: ${palette.error.main};
`;
