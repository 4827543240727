import {
    forwardRef,
    ForwardedRef,
    MutableRefObject,
    useImperativeHandle,
} from 'react';
import { useIMask } from 'react-imask';

import { TextInput } from '../TextInput';
import { MaskedInputProperties } from './MaskedInput.types';

const masks = {
    creditCard: '0000 0000 0000 [0000] [000]',
    sortCode: '00-00-00',
    shortDate: '00/00',
    longDate: '00/00/0000',
} as const;

/**
 * A masked input component.
 *
 * This component masks and formats the input to the chosen format.
 */
export const MaskedInput = forwardRef(
    (
        { mask, onChange, ...restProperties }: MaskedInputProperties,
        reference: ForwardedRef<HTMLInputElement>,
    ) => {
        const { ref } = useIMask(
            {
                mask: masks[mask],
                placeholderChar: 'X',
            },
            { onAccept: onChange },
        );

        useImperativeHandle(
            reference,
            () => (ref as MutableRefObject<HTMLInputElement>).current,
        );

        return (
            <TextInput
                ref={ref as MutableRefObject<HTMLInputElement>}
                placeholder={masks[mask].replace(/0/g, 'X')}
                {...restProperties}
            />
        );
    },
);

MaskedInput.displayName = 'MaskedInput';

export default MaskedInput;
