import getConfig from 'next/config';
import { useRef } from 'react';

import {
    ADDRESS_FIELDS,
    ControlledAddressInputHooksProperties,
    LoqateControl,
} from './ControlledAddressInput.types';

const {
    publicRuntimeConfig: { LOQATE_API_KEY },
} = getConfig();
export function useControlledAddressInput({
    reset,
    getValues,
    setIsManualAddressInput,
    trigger,
}: ControlledAddressInputHooksProperties) {
    const loqateControl = useRef<LoqateControl | null>(null);

    const handleLoqateLoad = () => {
        const fields = [
            {
                element: 'searchAddress',
                field: '',
                mode: window.pca.fieldMode.SEARCH,
            },
        ];

        const options = {
            key: `${LOQATE_API_KEY}`,
            countries: { codesList: 'GBR' },
            maxItems: 5,
        };

        const controlInstance = new window.pca.Address(fields, options);

        // Assign control to ref so it's accessible from the code
        loqateControl.current = controlInstance;
        controlInstance.listen('populate', (address: any) => {
            // Populate the manual input fields
            reset({
                ...getValues(),
                addressLine1: address.Line1,
                addressLine2: address.Line2,
                addressLine3: address.Line3,
                cityTown: address.City,
                postcode: address.PostalCode,
            });

            // Switch to manual address input
            setIsManualAddressInput(true);

            // Trigger validation on manual address fields
            // eslint-disable-next-line no-void
            void trigger(ADDRESS_FIELDS);
        });
    };

    const isLoqateControlVisible = () => {
        return (loqateControl.current as LoqateControl).visible();
    };

    return { handleLoqateLoad, isLoqateControlVisible };
}
