import { MaskedInput } from '../MaskedInput';
import { DateInputProperties } from './DateInput.types';

/**
 * The `DateInput` form component allows users to enter either a full (dd/mm/yyyy) or short (mm/yyyy) date.
 *
 * This input is validated on blur of the field by the form handler.
 */
export const DateInput = ({
    onBlur,
    onChange,
    errorMessage,
    isInvalid = false,
    format = 'long',
    id,
    value,
    autoComplete,
    disabled,
    placeholder,
    iconElementRight,
}: DateInputProperties) => {
    const inputPlaceholder =
        placeholder || (format === 'short' ? 'MM / YY' : 'DD / MM / YYYY');

    const mask = format === 'short' ? 'shortDate' : 'longDate';

    return (
        <MaskedInput
            disabled={disabled}
            mask={mask}
            inputProps={{ 'aria-label': 'date input', inputMode: 'numeric' }}
            placeholder={inputPlaceholder}
            id={id}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            errorMessage={errorMessage}
            isInvalid={isInvalid}
            autoComplete={autoComplete}
            iconElementRight={iconElementRight}
        />
    );
};

export default DateInput;
