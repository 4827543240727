export enum HealthOptions {
    Yes = 'yes',
    No = 'no',
}

export enum HealthOptionIds {
    Yes = 'health-question-yes',
    No = 'health-question-no',
}

export const healthQuestionMap = {
    [HealthOptionIds.Yes]: true,
    [HealthOptionIds.No]: false,
};
