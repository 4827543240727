import {
    Control,
    FieldErrors,
    UseFormClearErrors,
    UseFormGetValues,
    UseFormReset,
    UseFormResetField,
    UseFormTrigger,
} from 'react-hook-form';

import { PersonalDetailsFormSubmitData } from '../../PersonalDetailsForm/PersonalDetailsForm.types';

export interface ControlledAddressInput {
    control: Control<any>;
    resetField: UseFormResetField<any>;
    clearErrors: UseFormClearErrors<any>;
    defaultValues: AddressFieldValues;
}

export interface AddressFieldValues {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    cityTown: string;
    postcode: string;
}

// TODO: This type is incomplete. See https://tggitdev.atlassian.net/browse/MAUI-1681
export type LoqateControl = {
    visible: () => boolean;
};

export interface ControlledAddressInputHooksProperties {
    reset: UseFormReset<any>;
    getValues: UseFormGetValues<any>;
    setIsManualAddressInput: (value: boolean) => void;
    trigger: UseFormTrigger<
        { searchAddress: string } & PersonalDetailsFormSubmitData
    >;
}

export const ADDRESS_FIELDS = [
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'cityTown',
    'postcode',
] as const;

export interface ControlledAddressInputProperties {
    errors: FieldErrors<any>;
    defaultValues: AddressFieldValues;
    control: Control<any>;
    trigger: UseFormTrigger<any>;
    resetField: UseFormResetField<any>;
    clearErrors: UseFormClearErrors<any>;
    getValues: UseFormGetValues<any>;
    reset: UseFormReset<any>;
}
