import { Controller } from 'react-hook-form';

import { Asterisk } from '../../Asterisk';
import { ToggleButton } from '../../Forms';
import { Heading } from '../../Heading';
import { Paragraph } from '../../Paragraph';
import { StyledAlertContainer } from './ControlledHealthToggle.styled';
import { ControlledHealthToggleProperties } from './ControlledHealthToggle.types';
import { HealthOptionIds, HealthOptions } from '@tgg/common-types';

const healthConditionOptions = [
    { id: HealthOptionIds.No, label: HealthOptions.No },
    { id: HealthOptionIds.Yes, label: HealthOptions.Yes },
];

export function ControlledHealthToggle({
    control,
}: ControlledHealthToggleProperties) {
    return (
        <>
            <Heading variant="h2" alignOnDesktop="center">
                YOUR HEALTH
            </Heading>
            <Paragraph align="center">
                Do you have a disability that we may need to make reasonable
                adjustments for or any health conditions that could affect you
                while you&apos;re in the gym?
                <Asterisk spaceBefore />
            </Paragraph>
            <Controller
                name="health"
                control={control}
                rules={{ required: true }}
                render={({ field: { name, value, onChange } }) => (
                    <>
                        <ToggleButton
                            name={name}
                            selectedOptionId={value}
                            onChange={onChange}
                            options={healthConditionOptions}
                            layoutVariant="horizontal"
                            center
                        />
                        {value === HealthOptionIds.Yes && (
                            <StyledAlertContainer
                                type="information"
                                text="The Gym Manager will contact you after you have joined to arrange a convenient time to meet you at The Gym. They’ll show you around, answer any questions you might have and then activate your PIN"
                            />
                        )}
                    </>
                )}
            />
        </>
    );
}
